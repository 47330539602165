/* eslint-disable react/forbid-dom-props */
import classNames from 'classnames';
import Image from 'next/legacy/image';
import React from 'react';
import userImage from '@/public/assets/images/user-image.png';
import AvatarPrimary from './AvatarPrimary';
type ComponentType = typeof Image;
type AvatarMultipleProps = Omit<Parameters<ComponentType>[0], 'src'> & {
  // size?: number;
  sizePerImage: number;
  containerWidth?: number;
  alt?: string;
  srcArray: string[];
  sizes?: [n: number, sm?: number, md?: number, lg?: number];
};
const AvatarMultiple = ({
  srcArray,
  // size,
  sizePerImage,
  containerWidth,
  alt,
  sizes,
  ...otherProps
}: AvatarMultipleProps) => {
  const numOfImages = srcArray.length;
  const sizeForEach = sizePerImage || (numOfImages && containerWidth ? containerWidth / numOfImages : 40);
  return <div className="relative flex items-center justify-center -space-x-3" data-sentry-component="AvatarMultiple" data-sentry-source-file="AvatarMultiple.tsx">
      {srcArray.map((src, i) => {
      let avatarSize = sizeForEach;
      if (numOfImages % 2 && i + 1 === Math.round(numOfImages / 2)) {
        avatarSize *= 1.2;
      }
      if (!(numOfImages % 2) && (i + 1 === Math.round(numOfImages / 2) || i + 1 === Math.round(numOfImages / 2) + 1)) {
        avatarSize *= 1.2;
      }
      return <div
      // eslint-disable-next-line react/no-array-index-key
      key={src + i} className={classNames('flex items-center justify-center rounded-full border-4 border-white bg-gray-100 drop-shadow-sm'
      // avatarSize ? `h-${avatarSize} w-${avatarSize}` : ``
      )} style={{
        height: avatarSize + 4,
        width: avatarSize + 4
      }}>
            <AvatarPrimary alt={alt || ''} size={avatarSize} hideIndicators sizes={sizes} {...otherProps} resize className="" src={src || userImage} layout="responsive" />
          </div>;
    })}
    </div>;
};
export default AvatarMultiple;