'use client';

/* eslint-disable max-lines */
import { Dialog, Transition } from '@headlessui/react';
import classNames from 'classnames';
import React, { Fragment, useRef } from 'react';
import { ExternalLink, X } from 'react-feather';
import DialogError from '@/components/Modals/dialog/DialogError';
import Link from 'next/link';
import AvatarMultiple from '@/ui/Avatar/AvatarMultiple';
import useInterviewMeeting from '@/modules/Interviews/hooks/useInterviewMeeting';
import { MeetingDetails } from './types';
import ScheduleInterviewModal from '../jobs/schedule-interview/ScheduleInterviewModal';
interface Props {
  showModal: boolean;
  onCloseDialog: () => void;
  meetingDetails: MeetingDetails;
  handleRefreshUpcomingInterviews: () => void;
}
const MeetingDetailsModal = ({
  showModal,
  onCloseDialog,
  meetingDetails,
  handleRefreshUpcomingInterviews
}: Props) => {
  const buttonRef = useRef(null);
  const meetingHook = useInterviewMeeting({
    meetingDetails
  });
  // prettier-ignore
  const {
    showCancelMeetingDialog,
    setShowCancelMeetingDialog,
    cancelOptions,
    currentMeetingDetails,
    onMeetingReschedule
  } = meetingHook;
  const meetingAvatars = currentMeetingDetails.avatars || [];
  return <>
      <Transition.Root show={showModal} as={Fragment} data-sentry-element="unknown" data-sentry-source-file="MeetingDetailsModal.tsx">
        <Dialog as="div" className="relative z-10" onClose={onCloseDialog} initialFocus={buttonRef} data-sentry-element="Dialog" data-sentry-source-file="MeetingDetailsModal.tsx">
          <div className="fixed inset-0 bg-black bg-opacity-40" />
          <div className="fixed inset-0 overflow-hidden">
            <div className="absolute inset-0 overflow-hidden">
              <div className="pointer-events-none fixed inset-y-0 right-0 flex max-w-full pl-10 sm:pl-16">
                <Transition.Child as={Fragment} enter="transform transition ease-in-out duration-500 sm:duration-700" enterFrom="translate-x-full" enterTo="translate-x-0" leave="transform transition ease-in-out duration-500 sm:duration-700" leaveFrom="translate-x-0" leaveTo="translate-x-full" data-sentry-element="unknown" data-sentry-source-file="MeetingDetailsModal.tsx">
                  <Dialog.Panel className="pointer-events-auto w-screen max-w-md" data-sentry-element="unknown" data-sentry-source-file="MeetingDetailsModal.tsx">
                    <div className="flex h-full flex-col divide-y divide-gray-200 bg-white shadow-xl">
                      <div className="scrollbar-primary h-0 flex-1 overflow-y-auto">
                        <div>
                          <div className="meeting-details-gradient-bg relative flex h-28 w-full flex-col bg-center bg-no-repeat px-6 py-3 sm:h-40">
                            <div className="flex items-center justify-end">
                              {/* close */}
                              <div className="flex w-full justify-end" ref={buttonRef}>
                                <X className="text-gray-600" size={18} onClick={() => onCloseDialog()} data-sentry-element="X" data-sentry-source-file="MeetingDetailsModal.tsx" />
                              </div>
                            </div>
                          </div>
                          {/* -mt-10 sm:-mt-20   */}
                          <div className="mx-auto -mt-[100px] flex justify-center px-3"
                        // eslint-disable-next-line react/forbid-dom-props
                        style={{
                          marginTop: -100 / meetingAvatars.length
                        }}>
                            <AvatarMultiple srcArray={meetingAvatars} layout="responsive" sizePerImage={200 / meetingAvatars.length} data-sentry-element="AvatarMultiple" data-sentry-source-file="MeetingDetailsModal.tsx" />
                          </div>
                        </div>

                        <div className="mt-6 space-y-6 px-3 sm:mt-8 sm:px-6">
                          <div className="space-y-1">
                            <h3 className="text-base font-medium text-gray-900">Meeting Details</h3>
                            <p className="text-sm font-normal text-gray-500">
                              Here is a full details about the upcoming meeting with the Talent.
                            </p>
                          </div>

                          <div className="space-y-6 divide-y divide-gray-200 pb-4 sm:space-y-5">
                            {Object.entries(currentMeetingDetails).filter(([d]) => ['link', 'startDate', 'endDate', 'avatars', 'candidateId', 'jobApplicationId', 'interviewId'].every(k => k !== d)).map(([key, value]) => {
                            const timeDiff = new Date(currentMeetingDetails.startDate || '').getTime() - new Date().getTime(); // valid joining time is 15 mins before meeting time
                            const isValidJoiningTime = timeDiff >= 0 && timeDiff <= 15 * 60 * 1000;
                            return <div className="sm:grid sm:grid-cols-3 sm:items-start sm:gap-4 sm:border-t sm:border-gray-200 sm:pt-5" key={key}>
                                    <div className="">
                                      <p className="text-base font-medium capitalize text-gray-900">
                                        {key}:
                                      </p>
                                    </div>
                                    <div className={classNames('flex items-center sm:col-span-2', `${key === 'time' ? 'gap-x-2' : ''}`)}>
                                      <p className="text-sm font-medium text-gray-700">
                                        {value.toLocaleString()}
                                      </p>
                                      {key === 'time' && (isValidJoiningTime ? <Link target="_blank" rel="noopener noreferrer" aria-disabled={'true'} href={currentMeetingDetails.link} type="button" className={classNames(isValidJoiningTime ? `border-gray-300 bg-white text-gray-600` : `cursor-default border-gray-100 bg-white text-gray-300`, `inline-flex items-center gap-x-1 rounded-md border p-1 shadow-sm`)}>
                                            <span className="text-sm font-medium">Join</span>
                                            <ExternalLink size={16} />
                                          </Link> : <span>Not Started</span>)}
                                    </div>
                                  </div>;
                          })}
                          </div>
                        </div>
                      </div>
                      <div className="bottom-0 flex flex-col space-y-3 p-6 py-4 md:flex-row md:space-x-3 md:space-y-0">
                        <button type="button" className="btn-secondary flex flex-1 justify-center" onClick={() => setShowCancelMeetingDialog(true)}>
                          Cancel
                        </button>
                        <button type="button" className={`btn-primary flex-1`} onClick={() => meetingHook.setShowScheduleInterviewModal(true)}>
                          Reschedule
                        </button>
                      </div>
                    </div>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
      {showCancelMeetingDialog && <DialogError open={showCancelMeetingDialog} onClose={() => {
      setShowCancelMeetingDialog(false);
    }} title="Cancel Interview" description="The candidate would be notified about your decision to cancel" cancel={{
      text: 'Cancel',
      onCancel() {
        setShowCancelMeetingDialog(false);
      }
    }} confirm={{
      text: meetingHook.isCancellingInterview ? 'Proceeding...' : 'Proceed',
      onConfirm: () => meetingHook.onProceedCancel().then(success => {
        if (success) {
          onCloseDialog();
          handleRefreshUpcomingInterviews();
        }
      })
    }}>
          <p className="mt-2 text-sm font-semibold text-gray-700">Let us know your reason here.</p>
          {cancelOptions.map(({
        reason
      }) => {
        return <div role="button" key={reason} className="flex flex-col gap-y-3" onClick={() => meetingHook.setSelectedCancelReason(reason)} aria-hidden="true">
                <label htmlFor={reason} className="flex items-center gap-x-2 space-y-3">
                  <input type="radio" name="cancel-options" className="mt-3" value={reason} checked={meetingHook.selectedCancelReason === reason} onChange={e => meetingHook.setSelectedCancelReason(e.target.value)} />
                  <p className="text-sm font-normal text-gray-600">{reason}</p>
                </label>
              </div>;
      })}
        </DialogError>}
      {meetingHook.showScheduleInterviewModal && <ScheduleInterviewModal showModal={meetingHook.showScheduleInterviewModal} onCloseDialog={() => meetingHook.setShowScheduleInterviewModal(false)} selectedForInterview={[currentMeetingDetails.candidateId]} onMeetingReschedule={onMeetingReschedule} onFinishSchedule={onCloseDialog} />}
    </>;
};
export default MeetingDetailsModal;