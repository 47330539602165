'use client';
import { useCallback, useEffect, useState } from 'react';
import { apiUrl } from '@/api.config';
import { MeetingDetails } from '@/components/Modals/interviews/types';
import { InterviewAvailability } from '@/components/Modals/jobs/schedule-interview/types';
import Notify from '@/utils/Notify';
import { PostAsync } from '@/utils/Post';
import useProcessAsync from '@/hooks/useProcessAsync';
import { useAppSelector } from '@/store/hooks';
import { selectAuthState } from '@/store/selectors';

type Props = {
  meetingDetails: MeetingDetails;
};

export default function useInterviewMeeting({ meetingDetails }: Props) {
  const { user } = useAppSelector(selectAuthState);
  const [showCancelMeetingDialog, setShowCancelMeetingDialog] = useState(false);
  const [selectedCancelReason, setSelectedCancelReason] = useState('');
  const [showScheduleInterviewModal, setShowScheduleInterviewModal] = useState(false);
  const [currentMeetingDetails, setCurrentMeetingDetails] =
    useState<MeetingDetails>(meetingDetails);
  const { processAsync: cancelInterview, isLoading: isCancellingInterview } = useProcessAsync();

  const cancelOptions = [
    {
      id: 1,
      reason: 'We found a more suitable candidate.',
    },
    {
      id: 2,
      reason: 'The position is no longer available.',
    },
    {
      id: 3,
      reason: 'Hiring has been suspended for now.',
    },
    {
      id: 4,
      reason: 'Others',
    },
  ];

  const onProceedCancel = useCallback(async () => {
    if (!selectedCancelReason) {
      Notify.error('Please choose a cancel reason to proceed', "Can't Proceed");
      return false;
    }
    const res = await cancelInterview(async () => {
      const { interviewId } = currentMeetingDetails || {};
      return PostAsync({
        url: `${apiUrl}/cancel-interview/${interviewId || ''}`,
        method: 'POST',
        token: user?.token,
        body: {
          cancellation_reason: selectedCancelReason,
        },
      });
    });

    if (res?.meta?.status === 'success') {
      setShowCancelMeetingDialog(false);
      Notify.success(
        'The Interview has been cancelled successfully',
        'Interview Cancelled',
        undefined,
        5_000
      );
      return true;
    }
    Notify.error('Interview could not be cancelled. Please try again later', 'Not Cancelled');
    return false;
  }, [cancelInterview, currentMeetingDetails, selectedCancelReason, user?.token]);

  const onMeetingReschedule = useCallback((update: InterviewAvailability) => {
    setCurrentMeetingDetails((p) => ({
      ...p,
      link: update.meetingLink,
      startDate: new Date(update.startDate),
      endDate: new Date(update.endDate),
      duration: `${update.duration} Minutes`,
      date: new Date(update.startDate).toDateString(),

      time: `${
        new Date(update.startDate).toLocaleTimeString(undefined, {
          timeStyle: 'short',
        }) || 'N/A'
      } to ${
        new Date(update.endDate).toLocaleTimeString(undefined, { timeStyle: 'short' }) || 'N/A'
      }`,
    }));
  }, []);

  useEffect(() => {
    setCurrentMeetingDetails(meetingDetails);
  }, [meetingDetails]);

  const hookReturn = {
    showCancelMeetingDialog,
    setShowCancelMeetingDialog,
    cancelOptions,
    selectedCancelReason,
    setSelectedCancelReason,
    onProceedCancel,
    showScheduleInterviewModal,
    setShowScheduleInterviewModal,
    currentMeetingDetails,
    setCurrentMeetingDetails,
    onMeetingReschedule,
    isCancellingInterview,
  };

  return hookReturn;
}
